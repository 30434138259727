<template>
    <StateSwitch
        :state="status"
        :status-code="statusCode"
    >
        <slot
            name="default"
            :entry="entry"
        />
    </StateSwitch>
</template>

<script setup lang="ts">
import PageQuery from '~/graphql/queries/Page.graphql';
import { useCraftQuery, type CraftResponseObject } from '~/composables/useCraftQuery';
import type {
    Page_Page_Entry,
    Page_ProgramOverview_Entry
} from '~/@types/craft-schema';

const response = await useAsyncQuery({
    query: PageQuery,
    variables: {
        uri: useNormalizePath(useRoute().path)
    }
});

interface ResponseObject extends CraftResponseObject {
    entry: Ref<Page_Page_Entry|Page_ProgramOverview_Entry>;
}

const {
    status,
    statusCode,
    entry,
    seo
} = await useCraftQuery(response) as ResponseObject;

if (seo.value) {
    useHead(seo.value);
}
</script>
