<template>
    <QueryPage #default="{ entry }">
        <TemplateContentPage :entry="entry" />
    </QueryPage>
</template>

<script>
export default {
    middleware: [
        'mollieTransactionRedirect'
    ]
};
</script>
